// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/Users/aqkneo/Sites/experiments/redsqware-website-gatbsy/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-detail-js": () => import("/Users/aqkneo/Sites/experiments/redsqware-website-gatbsy/src/pages/blog-detail.js" /* webpackChunkName: "component---src-pages-blog-detail-js" */),
  "component---src-pages-blogs-js": () => import("/Users/aqkneo/Sites/experiments/redsqware-website-gatbsy/src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-case-studies-js": () => import("/Users/aqkneo/Sites/experiments/redsqware-website-gatbsy/src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contactus-js": () => import("/Users/aqkneo/Sites/experiments/redsqware-website-gatbsy/src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("/Users/aqkneo/Sites/experiments/redsqware-website-gatbsy/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-what-we-do-js": () => import("/Users/aqkneo/Sites/experiments/redsqware-website-gatbsy/src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/aqkneo/Sites/experiments/redsqware-website-gatbsy/.cache/data.json")

